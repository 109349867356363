import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import { Link } from 'gatsby';
import Image from '../../components/Image';
import Icon from '../../components/Icon';
import OutboundLink from '../../components/OutboundLink';

const BehavioralEconomicsPage = () => (
  <Layout title="Behavioral Economics">
    {/* BEHAVIORAL ECONOMICS HERO */}
    <Section className="hero">
      <Container>
        <Row>
          <Column size={12}>
            <Image
              filename="img-hero-behavioral-economics.jpg"
              alt="Behavioral Economics Hero Image"
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* INTRO SECTION */}
    <Section className="intro-section" id="intro-section">
      <Container>
        <Row>
          <Column size={10}>
            <h1 className="page-title">Behavioral Economics</h1>
            <h2 className="page-subhead">
              Examine the Impact of Our Everyday Decisions
            </h2>
            <p>
              Discover the ways that students’ lives are influenced by the
              economics of the world around them, including their everyday
              decision-making. Dive deeper into the human side of economics and
              let students reimagine their relationship with behavioral economic
              theory.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* BEHAVIORAL ECONOMICS */}
    <Section className="intro-videos">
      <Container>
        <Row className="br-all box-shadow">
          <Column size={6} className="intro-videos-left pr-0">
            <Image filename="img-be-card-featured.jpg" />
          </Column>
          <Column size={6} className="intro-videos-right">
            <h3>Behavioral Economics Videos</h3>
            <p>
              <strong>Topic:</strong> Behavioral Economics |{' '}
              <strong>Grade:</strong> 9–12
            </p>
            <p className="bold">
              Do humans defy logic and reason when they make financial
              decisions?
            </p>
            <p>
              Explore the ways psychology informs economic decision-making with
              this four-part Behavioral Economics Digital Lesson Series. Reframe
              core economics concepts in exciting new ways with a series of
              turnkey lesson resources including video, student activity,
              educator guide and more.
            </p>
            <div className="global__assets">
              <Link
                to="videos/introduction-to-behavioral-economics"
                className="asset__file"
              >
                <Icon name="play" /> 4 Videos
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* FOUR-COLUMN ASSETS*/}
    <Section className="four-column-assets">
      <Container>
        <Row className="assets">
          <Column size={3} className="asset multifile box-shadow br-all">
            <Image
              className="asset__image br-tl br-tr"
              filename="img-be-lesson-card-1.jpg"
            />
            <div className="asset__content">
              <h4 className="asset__type">Classroom Lesson</h4>
              <h3 className="asset__title">
                Introduction to Behavioral Economics
              </h3>
              <p className="asset__description">
                Dive deeper into students’ everyday thought processes and
                analyze how rational their decision-making is with a series of
                interactive scenarios.
              </p>
            </div>
            <div className="asset__files card">
              <Link
                to="videos/introduction-to-behavioral-economics"
                className="asset__file videos"
              >
                <Icon name="play" />
                Video
              </Link>
              <OutboundLink
                to="/pdfs/4_behavioral-economics/CME-Econ-Essentials-DLB-EducatorGuide-1-IntroToBehavioralEconomics.pdf"
                target="_blank"
                className="asset__file pdf"
              >
                <Icon name="download" />
                Educator Guide (.pdf)
              </OutboundLink>
              <OutboundLink
                to="/pdfs/4_behavioral-economics/CME-Econ-Essentials-DLB-PPT-1-IntroToBehavioralEconomics.pptx"
                target="_blank"
                className="asset__file ppt"
              >
                <Icon name="download" />
                Classroom Slideshow (.ppt)
              </OutboundLink>
              <OutboundLink
                to="/pdfs/4_behavioral-economics/CME-Econ-Essentials-DLB-EducatorGuide-1-ChallengeActivity.pdf"
                target="_blank"
                className="asset__file pdf"
              >
                <Icon name="download" />
                Challenge Activity (.pdf)
              </OutboundLink>
            </div>
          </Column>
          <Column size={3} className="asset multifile box-shadow br-all">
            <Image
              className="asset__image br-tl br-tr"
              filename="img-be-lesson-card-2.jpg"
            />
            <div className="asset__content">
              <h4 className="asset__type">Classroom Lesson</h4>
              <h3 className="asset__title">
                How We Are Affected by Preference
              </h3>
              <p className="asset__description">
                Find out how much preference influences people’s final decisions
                with a hands-on lesson exploring the way financial losses and
                gains impact human behavior.
              </p>
            </div>
            <div className="asset__files card">
              <Link
                to="videos/how-we-are-affected-by-preference"
                className="asset__file videos"
              >
                <Icon name="play" />
                Video
              </Link>
              <OutboundLink
                to="/pdfs/4_behavioral-economics/CME-Econ-Essentials-DLB-EducatorGuide-2-HowWeAreAffectedByPreference.pdf"
                target="_blank"
                className="asset__file pdf"
              >
                <Icon name="download" />
                Educator Guide (.pdf)
              </OutboundLink>
              <OutboundLink
                to="/pdfs/4_behavioral-economics/CME-Econ-Essentials-DLB-PPT-2-HowWeAreAffectedByPreference.pptx"
                target="_blank"
                className="asset__file ppt"
              >
                <Icon name="download" />
                Classroom Slideshow (.ppt)
              </OutboundLink>
              <OutboundLink
                to="/pdfs/4_behavioral-economics/CME-Econ-Essentials-DLB-EducatorGuide-2-ChallengeActivity.pdf"
                target="_blank"
                className="asset__file pdf"
              >
                <Icon name="download" />
                Challenge Activity (.pdf)
              </OutboundLink>
            </div>
          </Column>
          <Column size={3} className="asset multifile box-shadow br-all">
            <Image
              className="asset__image br-tl br-tr"
              filename="img-be-lesson-card-3.jpg"
            />
            <div className="asset__content">
              <h4 className="asset__type">Classroom Lesson</h4>
              <h3 className="asset__title">How We Are Affected by Others</h3>
              <p className="asset__description">
                Discover the ways that outside influences can “nudge” our
                decision-making without our even realizing it with a series of
                student activities.
              </p>
            </div>
            <div className="asset__files card">
              <Link
                to="videos/how-we-are-affected-by-others"
                className="asset__file videos"
              >
                <Icon name="play" />
                Video
              </Link>
              <OutboundLink
                to="/pdfs/4_behavioral-economics/CME-Econ-Essentials-DLB-EducatorGuide-3-HowWeAreAffectedByOthers.pdf"
                target="_blank"
                className="asset__file pdf"
              >
                <Icon name="download" />
                Educator Guide (.pdf)
              </OutboundLink>
              <OutboundLink
                to="/pdfs/4_behavioral-economics/CME-Econ-Essentials-DLB-PPT-3-HowWeAreAffectedbyOthers.pptx"
                target="_blank"
                className="asset__file ppt"
              >
                <Icon name="download" />
                Classroom Slideshow (.ppt)
              </OutboundLink>
              <OutboundLink
                to="/pdfs/4_behavioral-economics/CME-Econ-Essentials-DLB-EducatorGuide-3-ChallengeActivity.pdf"
                target="_blank"
                className="asset__file pdf"
              >
                <Icon name="download" />
                Challenge Activity (.pdf)
              </OutboundLink>
            </div>
          </Column>
          <Column size={3} className="asset multifile box-shadow br-all">
            <Image
              className="asset__image br-tl br-tr"
              filename="img-be-lesson-card-4.jpg"
            />
            <div className="asset__content">
              <h4 className="asset__type">Classroom Lesson</h4>
              <h3 className="asset__title">How We Are Affected by Price</h3>
              <p className="asset__description">
                Take a closer look at everyday buying habits as students learn
                how to navigate price “anchoring” and understand the influence
                of price on economics.
              </p>
            </div>
            <div className="asset__files card">
              <Link
                to="videos/how-we-are-affected-by-price"
                className="asset__file videos"
              >
                <Icon name="play" />
                Video
              </Link>
              <OutboundLink
                to="/pdfs/4_behavioral-economics/CME-Econ-Essentials-DLB-EducatorGuide-4-HowWeAreAffectedByPrice.pdf"
                target="_blank"
                className="asset__file pdf"
              >
                <Icon name="download" />
                Educator Guide (.pdf)
              </OutboundLink>
              <OutboundLink
                to="/pdfs/4_behavioral-economics/CME-Econ-Essentials-DLB-PPT-4-HowWeAreAffectedByPrice.pptx"
                target="_blank"
                className="asset__file ppt"
              >
                <Icon name="download" />
                Classroom Slideshow (.ppt)
              </OutboundLink>
              <OutboundLink
                to="/pdfs/4_behavioral-economics/CME-Econ-Essentials-DLB-EducatorGuide-4-ChallengeActivity.pdf"
                target="_blank"
                className="asset__file pdf"
              >
                <Icon name="download" />
                Challenge Activity (.pdf)
              </OutboundLink>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* STANDARDS-ALIGNED RESOURCES  */}
    <Section className="standards-aligned-resourses">
      <Container>
        <Row>
          <Column size={9}>
            <h2>Standards-Aligned Resources</h2>
            <h3>Build your lessons with handy standards guide</h3>
            <p>
              Econ Essentials resources are created in accordance with national
              education standards. Check out our standards-aligned tools to help
              build your lessons.
            </p>
            <a href="#sar-modal" className="button mt-1">
              Learn More <Icon name="caratright" />
            </a>
          </Column>
          <Column size={3}>
            <Image filename="img-standards-aligned-resources.jpg" />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* SUPPORTING RESOURCES */}
    <Section className="four-column-assets">
      <Container>
        <Row>
          <h2>Supporting Resources</h2>
        </Row>
        <Row className="assets horizontal">
          <Column className="asset br-tl bb-blue">
            <Image
              className="asset__image br-tl br-tr"
              filename="img-asset-educator-roadmap.jpg"
            />
            <div className="asset__content">
              <h3 className="asset__title">
                Career & Technical Education Educator Roadmap
              </h3>
              <p className="asset__description">
                Econ Essentials resources can be used in a variety of subject
                areas—everything from economics and social studies to personal
                finance and business to career and technical education (CTE) and
                family and consumer sciences (FCS).
              </p>
              <div className="asset__files">
                <OutboundLink
                  to="/pdfs/2_economic-principles/CME-EconEssentials-CTERoadMap.pdf"
                  target="_blank"
                  className="asset__file"
                >
                  <Icon name="download" />
                  Educator Guide (.pdf)
                </OutboundLink>
              </div>
            </div>
          </Column>
          <Column className="asset br-tl bb-blue">
            <Image
              className="asset__image br-tl br-tr"
              filename="img-asset-investment-clubs.jpg"
            />
            <div className="asset__content">
              <h3 className="asset__title">
                Econ Essentials and Other Resources for Student Investment Clubs
              </h3>
              <p className="asset__description">
                Explore core economics concepts outside the classroom with Econ
                Essentials resources that are designed to work in afterschool
                clubs.
              </p>
              <div className="asset__files">
                <OutboundLink
                  to="/pdfs/2_economic-principles/CME-EconEssentials-IvestmentClubRoadMap.pdf"
                  target="_blank"
                  className="asset__file"
                >
                  <Icon name="download" />
                  Educator Guide (.pdf)
                </OutboundLink>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* QUOTE CALLOUT */}
    <Section className="quote-callout">
      <Container>
        <Row>
          <Column size={7} className="quote-callout__text">
            <h3 className="quote-callout__quote">
              “With the material we’re learning about in Econ Essentials, it’s
              all things that students can use outside of school.”
            </h3>
            <p className="quote-callout__source">
              <span className="quote-callout__source-name">Bryen Hall</span>
              <br />
              Economics Instructor, Ogden International School
              <br />
              Chicago, Illinois
            </p>
          </Column>
          <Column size={5} className="quote-callout__image pt-3">
            <Image filename="img-be-quote-banner.svg" />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* FUTURES FUNDAMENTALS FOOTER IMAGE*/}
    <Section className="above-footer-banner">
      <Container>
        <Row>
          <Column>
            <Image
              filename="behavioral-economics-above-footer-banner.jpg"
              className="bb-orange"
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* SIGNUP FOR UPDATES */}
    <Section className="signup-updates">
      <Container>
        <Row>
          <Column size={7}>
            <h2>Sign Up for Updates</h2>
            <p>
              Want to be among the first to learn about exciting new resources
              and upcoming learning opportunities? Stay connected with Econ
              Essentials and sign up to receive updates today!
            </p>
            <OutboundLink
              to="https://info.discoveryeducation.com/Econ-Essentials-Powered-by-Futures-Fundamentals_Opt-Ins.html"
              className="button mt-1"
            >
              Learn More <Icon name="caratright" />
            </OutboundLink>
          </Column>
          <Column size={4} offset={1}>
            <Image filename="hp-signip-map.svg" />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* PURE CSS STANDARDS-ALIGNED RESOURCES POPUP |
        THIS SHOULD USE THE MODAL COMPONENT */}
    <div id="sar-modal" className="overlay">
      <div className="sar">
        <a className="close" href="#">
          &times;
        </a>
        <h2 className="sar__heading">Standards-Aligned Resources</h2>
        <p>
          Download the standards below to reference the corresponding resource
          and standards connection.
        </p>
        <ul className="sar__resources">
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Correlation-OverviewChart.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Overview of National Standards
              Correlations
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Voluntary-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Voluntary National Content Standards in
              Economics
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-PersFin-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Personal Financial
              Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-SS-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> C3 Framework for Social Studies State
              Standards
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-FCS-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Family and
              Consumer Sciences Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Business-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Business Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-CT-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Common Career Technical Core
            </OutboundLink>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
);

export default BehavioralEconomicsPage;
